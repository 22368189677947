import AboutSection from "./sections/About";
import FactsSection from "./sections/Facts";
import SkillsSection from "./sections/Skills";
import ResumeSection from "./sections/Resume";
import PortfolioSection from "./sections/Portfolio";
import ServicesSection from "./sections/Services";
//import TestimonialsSection from "./sections/Testimonials";
import ContactSection from "./sections/Contact";

const Content = () => {
  return (
    <>
      <AboutSection/>
      <FactsSection/>
      <SkillsSection/>
      <ResumeSection/>
      <PortfolioSection/>
      <ServicesSection/>
      {/*<TestimonialsSection/>*/}
      <ContactSection/>
    </>
  )
}

export default Content;
