import './App.css';
import Header from "./components/Header";
import Footer from "./components/Footer";
import Content from "./components/Content";
import Hero from "./components/basic/Hero";

function App() {
  return (
    <>
      <Header/>
      <Hero/>
      <Content/>
      <Footer/>
    </>
  );
}

export default App;
